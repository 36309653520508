import { default as aboutcGvMViY0SnMeta } from "/opt/buildhome/repo/pages/about.vue?macro=true";
import { default as indexqCSs1MIPZ1Meta } from "/opt/buildhome/repo/pages/blog/[slug]/index.vue?macro=true";
import { default as indexnCitm8tluDMeta } from "/opt/buildhome/repo/pages/blog/index.vue?macro=true";
import { default as businessesb6vXMnR9LbMeta } from "/opt/buildhome/repo/pages/businesses.vue?macro=true";
import { default as customerszW9MCEDY8JMeta } from "/opt/buildhome/repo/pages/customers.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as privacy69l1Gm1VSzMeta } from "/opt/buildhome/repo/pages/privacy.vue?macro=true";
import { default as product1h3TEMIAg5Meta } from "/opt/buildhome/repo/pages/product.vue?macro=true";
import { default as providersZu8d7wvdlWMeta } from "/opt/buildhome/repo/pages/providers.vue?macro=true";
import { default as termsjw1825O2ZdMeta } from "/opt/buildhome/repo/pages/terms.vue?macro=true";
export default [
  {
    name: aboutcGvMViY0SnMeta?.name ?? "about",
    path: aboutcGvMViY0SnMeta?.path ?? "/about",
    meta: aboutcGvMViY0SnMeta || {},
    alias: aboutcGvMViY0SnMeta?.alias || [],
    redirect: aboutcGvMViY0SnMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: indexqCSs1MIPZ1Meta?.name ?? "blog-slug",
    path: indexqCSs1MIPZ1Meta?.path ?? "/blog/:slug",
    meta: indexqCSs1MIPZ1Meta || {},
    alias: indexqCSs1MIPZ1Meta?.alias || [],
    redirect: indexqCSs1MIPZ1Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/blog/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexnCitm8tluDMeta?.name ?? "blog",
    path: indexnCitm8tluDMeta?.path ?? "/blog",
    meta: indexnCitm8tluDMeta || {},
    alias: indexnCitm8tluDMeta?.alias || [],
    redirect: indexnCitm8tluDMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: businessesb6vXMnR9LbMeta?.name ?? "businesses",
    path: businessesb6vXMnR9LbMeta?.path ?? "/businesses",
    meta: businessesb6vXMnR9LbMeta || {},
    alias: businessesb6vXMnR9LbMeta?.alias || [],
    redirect: businessesb6vXMnR9LbMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/businesses.vue").then(m => m.default || m)
  },
  {
    name: customerszW9MCEDY8JMeta?.name ?? "customers",
    path: customerszW9MCEDY8JMeta?.path ?? "/customers",
    meta: customerszW9MCEDY8JMeta || {},
    alias: customerszW9MCEDY8JMeta?.alias || [],
    redirect: customerszW9MCEDY8JMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/customers.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: privacy69l1Gm1VSzMeta?.name ?? "privacy",
    path: privacy69l1Gm1VSzMeta?.path ?? "/privacy",
    meta: privacy69l1Gm1VSzMeta || {},
    alias: privacy69l1Gm1VSzMeta?.alias || [],
    redirect: privacy69l1Gm1VSzMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: product1h3TEMIAg5Meta?.name ?? "product",
    path: product1h3TEMIAg5Meta?.path ?? "/product",
    meta: product1h3TEMIAg5Meta || {},
    alias: product1h3TEMIAg5Meta?.alias || [],
    redirect: product1h3TEMIAg5Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/product.vue").then(m => m.default || m)
  },
  {
    name: providersZu8d7wvdlWMeta?.name ?? "providers",
    path: providersZu8d7wvdlWMeta?.path ?? "/providers",
    meta: providersZu8d7wvdlWMeta || {},
    alias: providersZu8d7wvdlWMeta?.alias || [],
    redirect: providersZu8d7wvdlWMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/providers.vue").then(m => m.default || m)
  },
  {
    name: termsjw1825O2ZdMeta?.name ?? "terms",
    path: termsjw1825O2ZdMeta?.path ?? "/terms",
    meta: termsjw1825O2ZdMeta || {},
    alias: termsjw1825O2ZdMeta?.alias || [],
    redirect: termsjw1825O2ZdMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/terms.vue").then(m => m.default || m)
  }
]