import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import vueuse_head_polyfill_M7DKUOwKp5 from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/vueuse-head-polyfill.js";
import router_jmwsqit4Rs from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import gtag_client_R70iZRv6jK from "/opt/buildhome/repo/plugins/gtag.client.js";
import matomo_client_hZHcd59ZPU from "/opt/buildhome/repo/plugins/matomo.client.js";
import mq_client_1yMDG8nTmt from "/opt/buildhome/repo/plugins/mq.client.js";
import sanity_image_builder_bQsAocKPDm from "/opt/buildhome/repo/plugins/sanity-image-builder.js";
import social_sharing_iQgTMDG1bs from "/opt/buildhome/repo/plugins/social-sharing.js";
import sticky_p7DS3xLDPF from "/opt/buildhome/repo/plugins/sticky.js";
import store_pBBVV6Lb3L from "/opt/buildhome/repo/plugins/store.js";
export default [
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  vueuse_head_polyfill_M7DKUOwKp5,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  gtag_client_R70iZRv6jK,
  matomo_client_hZHcd59ZPU,
  mq_client_1yMDG8nTmt,
  sanity_image_builder_bQsAocKPDm,
  social_sharing_iQgTMDG1bs,
  sticky_p7DS3xLDPF,
  store_pBBVV6Lb3L
]